import { PerformanceDatapoint } from 'owa-analytics';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';
import { isExecuting } from './utils/isExecuting';
import { addCustomWaterfallIfExecuting } from './utils/addCustomWaterfallIfExecuting';

import type { TraceErrorObject } from 'owa-trace';
import type { IUTEPlacesAgendaPanelLoadE2EArgs } from 'owa-unified-telemetry';

let PlacesAgendaPanelLoadE2E: PerformanceDatapoint | undefined = undefined;

export const STEP_TO_WATERFALL_INDEX_MAP = {
    calendarView: 1, // calendar view to render events in agenda view
} as const;

export function PlacesAgendaPanelLoadE2EStart() {
    if (isExecuting(PlacesAgendaPanelLoadE2E)) {
        PlacesAgendaPanelLoadE2ECancel('New agenda panel started.');
        PlacesAgendaPanelLoadE2E = undefined;
    }
    const dp: PerformanceDatapoint = new PerformanceDatapoint('PlacesAgendaPanelLoadE2E', {
        timeout: PlacesMarkerTimeout,
    });

    PlacesAgendaPanelLoadE2E = createPlacesPerformanceMarker(dp);

    return PlacesAgendaPanelLoadE2E;
}

export function PlacesAgendaPanelLoadE2EComplete(
    loadSuccessful: boolean,
    errorSource = '',
    errorObject?: TraceErrorObject
) {
    PlacesAgendaPanelLoadE2E?.addUnifiedTelemetryData({
        eventName: 'PlacesAgendaPanelLoadE2E',
        data: {} as IUTEPlacesAgendaPanelLoadE2EArgs /** PerfProperties and PerfCustomWaterfall is handled in logUnifiedDatapoint & mergeEvents */,
    });

    const errorSourceObj = loadSuccessful
        ? {}
        : { errorSource: !!errorSource ? errorSource : 'EmptyErrorSourcePleaseUpdate' };
    actActionIfExecuting(
        PlacesAgendaPanelLoadE2E,
        {
            loadSuccessful_1: loadSuccessful,
            ...errorSourceObj,
        },
        loadSuccessful ? 'end' : 'endWithError',
        errorObject
    );
}

export const PlacesAgendaPanelLoadE2ECheckmark = (
    checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP
) => {
    addCustomWaterfallIfExecuting(
        PlacesAgendaPanelLoadE2E,
        STEP_TO_WATERFALL_INDEX_MAP[checkpoint],
        checkpoint as string
    );
};

export function PlacesAgendaPanelLoadE2ECancel(reason = '') {
    actActionIfExecuting(
        PlacesAgendaPanelLoadE2E,
        {
            reason,
        },
        'invalidate'
    );
}
