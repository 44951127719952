import { PerformanceCoreDatapoint } from 'owa-analytics';
import { scrubForPii } from 'owa-config';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';

import type { IUTEPlacesPageLoaderE2EArgs } from 'owa-unified-telemetry';

/**
 * Measures from the beginning of the Places bootstrap (initializeState) in the owa-shared-bootstrap and ends
 * when Places Dashboard swimline (TeamWorkPlan.tsx) receives data to render. This is measured as Dashboard Page Load Time (PLT).
 */
let PlacesPageLoaderE2E: PerformanceCoreDatapoint | undefined = undefined;
let CurrentLoader: string;

export function PlacesPageLoaderE2EStart(loaderName: string) {
    if (loaderName !== CurrentLoader) {
        if (CurrentLoader) {
            PlacesPageLoaderE2ECancel('New loader started.');
        }

        CurrentLoader = loaderName;

        const dp: PerformanceCoreDatapoint = new PerformanceCoreDatapoint('PlacesPageLoaderE2E', {
            timeout: PlacesMarkerTimeout,
        });

        PlacesPageLoaderE2E = createPlacesPerformanceMarker(dp);
    }

    return PlacesPageLoaderE2E;
}

export function PlacesPageLoaderE2EComplete(
    loaderName: string,
    loadSuccessful: boolean,
    errorSource = ''
) {
    PlacesPageLoaderE2E?.addUnifiedTelemetryData({
        eventName: 'PlacesPageLoaderE2E',
        data: {
            LoaderName: loaderName,
        } as IUTEPlacesPageLoaderE2EArgs /** PerfProperties and PerfCustomWaterfall is handled in logUnifiedDatapoint & mergeEvents */,
    });

    actActionIfExecuting(
        PlacesPageLoaderE2E,
        {
            loadSuccessful_1: loadSuccessful,
            errorSource: scrubForPii(errorSource),
            loaderName,
        },
        loadSuccessful ? 'end' : 'endWithError'
    );

    CurrentLoader = '';
}

export function PlacesPageLoaderE2ECancel(reason = '') {
    actActionIfExecuting(
        PlacesPageLoaderE2E,
        {
            reason,
        },
        'invalidate'
    );
}
