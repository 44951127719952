import { isAnalyticsHomepageRegex } from 'hybridspace-telemetry';
import { isFeatureEnabled } from 'owa-feature-flags';

// Check shouldStartPlacesAnalyticsAlt.Test.ts to understand the scenarios covered
export function shouldStartPlacesAnalyticsAlt(pathname: string) {
    return isFeatureEnabled('msplaces-home-page') && isPlacesAnalyticsHomePage(pathname);
}

export function isPlacesAnalyticsHomePage(pathname: string) {
    const homepageRegex = new RegExp(isAnalyticsHomepageRegex, 'i' /* Flag for case insensitive */);
    return (pathname.match(homepageRegex) || []).length > 0;
}
