import type { CustomData, CustomWaterfallRange } from 'owa-analytics-types';

import { PerformanceCoreDatapoint } from 'owa-analytics';
import { addPASKeyMetric } from 'owa-performance';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { addBootCustomData } from './utils/addBootCustomData';
import { addCustomWaterfallIfExecuting } from './utils/addCustomWaterfallIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';

import type { PlacesBootSource } from './types/PlacesBootSource';
import type { IUTEPlacesDashboardLoadE2EArgs } from 'owa-unified-telemetry';

/**
 * These strings are expensive to store in kusto. keeping it short.
 * This list is the source of truth.
 */
export const STEP_TO_WATERFALL_INDEX_MAP: {
    [checkpoint: string]: CustomWaterfallRange;
} = {
    pfcs: 1, // DEPRECATED pretch collaborators and schedule
    ps: 2, // post start in bootstrap
    idbs: 3, // dashboard indexDB started
    idbe: 4, // dashboard indexDB ended
    nhm: 5, // nav header mounted
    hdbm: 6, // home dashboard mounted. this is useful as we can check the time difference between main module until home dashboard lazy loaded.
    crs: 7, // dashboard's Collaborator Request Started
    cre: 8, // dashboard's Collaborator Request Ended
    srs: 9, // dashboard's Schedule Request Started
    sre: 10, // dashboard's Schedule Request Ended
    wpls: 11, // Workplan Load Started
    pre: 12, // DEPRECATED dashboard's Persona Request ended
    sdss: 13, // DEPRECATED dashboard's SDS cache load started
    sdse: 14, // DEPRECATED dashboard's SDS cache load ended
    pts: 15, // PlacesTenentSettings response received
};

/**
 * Measures from the beginning of the Places bootstrap (initializeState) in the owa-shared-bootstrap and ends
 * when Places Dashboard swimline (TeamWorkPlan.tsx) receives data to render. This is measured as Dashboard Page Load Time (PLT).
 */
let PlacesDashboardLoadE2E: PerformanceCoreDatapoint | undefined = undefined;

let shouldStartDashboardLoadE2E = true;
export function PlacesDashboardLoadE2EStart(bootSource: PlacesBootSource) {
    if (shouldStartDashboardLoadE2E) {
        shouldStartDashboardLoadE2E = false;

        const dp = new PerformanceCoreDatapoint('PlacesDashboardLoadE2E', {
            timeout: PlacesMarkerTimeout,
        });
        PlacesDashboardLoadE2E = createPlacesPerformanceMarker(dp, bootSource);
    }
    return PlacesDashboardLoadE2E;
}

let shouldCompleteDashboardLoadE2E = true;
export function PlacesDashboardLoadE2EComplete(loadSuccessful: boolean, errorSource = '') {
    if (shouldCompleteDashboardLoadE2E && PlacesDashboardLoadE2E) {
        shouldCompleteDashboardLoadE2E = false;

        // TODO: Data is casted because the current interface doesn't support optional data. loicbe is looking into this.
        PlacesDashboardLoadE2E.addUnifiedTelemetryData({
            eventName: 'PlacesDashboardLoadE2E',
            data: {} as IUTEPlacesDashboardLoadE2EArgs /** PerfProperties and PerfCustomWaterfall is handled in logUnifiedDatapoint & mergeEvents */,
        });

        addBootCustomData(PlacesDashboardLoadE2E);
        addPASKeyMetric('PlacesAppLoadSucceeded');

        const errorSourceObj = loadSuccessful
            ? {}
            : { errorSource: !!errorSource ? errorSource : 'EmptyErrorSourcePleaseUpdate' };
        actActionIfExecuting(
            PlacesDashboardLoadE2E,
            {
                loadSuccessful_1: loadSuccessful,
                ...errorSourceObj,
            },
            loadSuccessful ? 'end' : 'endWithError'
        );
    }
}

let shouldCancelDashboardLoadE2E = true;
export function PlacesDashboardLoadE2ECancel(reason = '') {
    if (shouldCancelDashboardLoadE2E) {
        shouldCancelDashboardLoadE2E = false;
        actActionIfExecuting(PlacesDashboardLoadE2E, { reason }, 'invalidate');
    }
}

export const PlacesDashboardLoadE2ECheckmark = (
    checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP
) => {
    addCustomWaterfallIfExecuting(
        PlacesDashboardLoadE2E,
        STEP_TO_WATERFALL_INDEX_MAP[checkpoint],
        checkpoint as string
    );
};

export const PlacesDashboardLoadE2ECustomData = (customData: CustomData) => {
    actActionIfExecuting(PlacesDashboardLoadE2E, customData);
};
