import type { PerformanceDatapoint } from 'owa-analytics';
import { DatapointStatus } from 'owa-analytics';
import { isExecuting } from './isExecuting';

import type { CustomData } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace';

export function actActionIfExecuting(
    datapoint: PerformanceDatapoint | undefined,
    customData: CustomData,
    action?: 'end' | 'invalidate' | 'endWithError',
    errorObject?: TraceErrorObject
) {
    if (isExecuting(datapoint)) {
        datapoint.addCustomData(customData);

        if (action === 'end') {
            datapoint.end();
        } else if (action === 'endWithError') {
            // TODO: update this to be support other errors. most likely has to be an argument
            datapoint.endWithError(DatapointStatus.ServerError, errorObject);
        } else if (action === 'invalidate') {
            datapoint.invalidate();
        }
    }
}
