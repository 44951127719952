import { PerformanceDatapoint } from 'owa-analytics';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';

import type { IUTEPlacesGuidanceCardsLoadArgs } from 'owa-unified-telemetry';
import type { CustomData } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace';

/**
 * Measures from the beginning of the individual Hybrid Guidance card load and ends when all cards are loaded.
 */
let PlacesGuidanceCardsLoad: PerformanceDatapoint | undefined = undefined;

export function PlacesGuidanceCardsLoadStart() {
    const dp = new PerformanceDatapoint('PlacesGuidanceCardsLoad', {
        timeout: PlacesMarkerTimeout,
    });
    PlacesGuidanceCardsLoad = createPlacesPerformanceMarker(dp);
    return PlacesGuidanceCardsLoad;
}

export function PlacesGuidanceCardsLoadComplete(
    loadSuccessful: boolean,
    errorObject?: TraceErrorObject
) {
    // TODO: Data is casted because the current interface doesn't support optional data.
    PlacesGuidanceCardsLoad?.addUnifiedTelemetryData({
        eventName: 'PlacesGuidanceCardsLoad',
        data: {} as IUTEPlacesGuidanceCardsLoadArgs,
    });
    actActionIfExecuting(
        PlacesGuidanceCardsLoad,
        {
            loadSuccessful_1: loadSuccessful,
        },
        loadSuccessful ? 'end' : 'endWithError',
        errorObject
    );
}

let shouldRunLoadE2ECancel = true;
export function PlacesGuidanceCardsLoadCancel(reason = '') {
    if (shouldRunLoadE2ECancel) {
        shouldRunLoadE2ECancel = false;
        actActionIfExecuting(PlacesGuidanceCardsLoad, { reason }, 'invalidate');
    }
}
export const PlacesGuidanceCardsLoadCustomData = (customData: CustomData) => {
    actActionIfExecuting(PlacesGuidanceCardsLoad, customData);
};
