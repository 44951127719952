import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';
import { PlacesDashboardLoadE2ECheckmark } from './placesDashboardLoadE2E';
import { PlacesHomepageV3LoadE2ECheckmark } from './placesHomepageV3LoadE2E';

export default function placesFwkBootPostStartAction() {
    // Mark V1 and V3 homepage
    PlacesDashboardLoadE2ECheckmark('ps');
    PlacesHomepageV3LoadE2ECheckmark('ps');
    placesAnalyticsAppLoadAction({
        scenario: 'BootstrapCompleted',
    });
}
