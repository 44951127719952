import { STEP_TO_WATERFALL_INDEX_MAP } from './placesAnalyticsAppLoadE2E';
import { PlacesAnalyticsCoreEventBase } from './placesAnalyticsCoreEventBase';
import { PlacesMarkerTimeout } from './constants';
import { PerformanceCoreDatapoint } from 'owa-analytics';

/**
 * Measures from the beginning of Admin Role Switch (from Employee) and ends on completion of home page load.
 */
class PlacesAnalyticsAdminRoleSwitchE2E extends PlacesAnalyticsCoreEventBase<
    typeof STEP_TO_WATERFALL_INDEX_MAP
> {
    protected createNewPerformanceCoreDatapoint(): PerformanceCoreDatapoint {
        return new PerformanceCoreDatapoint('PlacesAnalyticsAdminRoleSwitchE2E', {
            timeout: PlacesMarkerTimeout,
        });
    }

    protected getStepToWaterfallIndexMap() {
        return STEP_TO_WATERFALL_INDEX_MAP;
    }
}

export const placesAnalyticsAdminRoleSwitchE2E = new PlacesAnalyticsAdminRoleSwitchE2E();
