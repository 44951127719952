import type { UTPlaceResourceType, UTPlaceReservationOriginType } from 'owa-unified-telemetry';
import { logUsage } from 'owa-analytics';

import type { CustomDataMap, UnifiedEvent } from 'owa-analytics-types';
import getEntryPoint from './utils/getEntryPoint';

/**
 * Standardized logging for workspaces usage
 *
 * eventName IS A TYPE TO KEEP TRACK OF LOGGED EVENTS IN WORKSPACES
 * MAKE SURE TELEMETRY IS UPDATED IN https://outlookweb.visualstudio.com/MicrosoftPlaces/_wiki/wikis/MicrosoftPlaces.wiki/5908/Logged-Telemetry-Workspaces
 *
 * TODO: REFACTOR LEGACY TELEMETRY TO ALIGN WITH UNIFIED TELEMETRY. WI 180399
 */
export default function logWorkspacesUsage(
    eventName:
        | 'PlacesWorkspacesPlaceSelected'
        | 'PlacesWorkspacesSearched'
        | 'PlacesWorkspacesSearchInvalidInput'
        | 'PlacesWorkspacesCreateReservation'
        | 'PlacesWorkspacesEditReservation'
        | 'PlacesWorkspacesDeleteReservation'
        | 'PlacesWorkspacesCheckinReservation'
        | 'PlacesWorkspacesReleaseReservation'
        | 'PlacesWorkspacesBooking',
    customData: {
        // Preferred to use this if only one placeId is logged
        PlaceId?: string;
        PlaceType?: UTPlaceResourceType;
        // The following is used for search bar.
        SearchBuildingId?: string;
        SearchFloorLabel?: string;
        SearchDateRangeStart?: string;
        SearchDateRangeEnd?: string;
        // Reservation
        ReservationId?: string;
        ReservationStartTime?: string;
        ReservationEndTime?: string;
        // Origin
        Origin?: UTPlaceReservationOriginType;
        // Common
        Error?: string;
    },
    unifiedTelemetry?: UnifiedEvent | undefined
) {
    // go through each map, remove undefined or null properties
    // not checking for falsy value because 0 is a possible value
    const cleanCustomData: CustomDataMap = {};
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(customData).forEach(key => {
        const customDataKey = key as keyof typeof customData;
        if (customData[customDataKey] !== undefined || customData[customDataKey] !== null) {
            cleanCustomData[key] = customData[customDataKey];
        }
    });
    cleanCustomData['entryPoint'] = getEntryPoint();
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(eventName, cleanCustomData, { unifiedTelemetry });
}
