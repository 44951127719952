import { hybridGuidancePageLoadAction, placesGuidanceCardsLoadAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import {
    PlacesHybridGuidanceLoadE2EComplete,
    PlacesHybridGuidanceLoadE2EStart,
    PlacesHybridGuidanceLoadE2ECustomData,
} from '../placesHybridGuidanceLoadE2E';

orchestrator(hybridGuidancePageLoadAction, ({ rootComponentName, errorObject }) => {
    switch (rootComponentName) {
        case 'Start':
            PlacesHybridGuidanceLoadE2EStart();
            placesGuidanceCardsLoadAction('Start');
            break;

        case 'FWHLoaded':
            PlacesHybridGuidanceLoadE2ECustomData({ FWHLoaded: true });
            PlacesHybridGuidanceLoadE2EComplete(true /** load successful */);
            break;

        case 'End':
            PlacesHybridGuidanceLoadE2ECustomData({ FWHLoaded: false });
            PlacesHybridGuidanceLoadE2EComplete(true /** load successful */);
            break;

        default:
            if (errorObject) {
                PlacesHybridGuidanceLoadE2EComplete(false /** load failed */, errorObject);
            }
    }
});
