import { PerformanceDatapoint } from 'owa-analytics';
import { scrubForPii } from 'owa-config';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';
import { PlacesMarkerTimeout } from './constants';

/**
 * Measures the time it takes to switch between buildings. We end when user see Building Card UX
 */
let PlacesBuildingSwitchE2E: PerformanceDatapoint | undefined = undefined;
let CurrentSwitchId: string;

export function placesBuildingSwitchE2EStart(buildingId: string, origin: string) {
    if (buildingId !== CurrentSwitchId) {
        if (CurrentSwitchId) {
            PlacesBuildingSwitchE2ECancel('New Building switch started.');
        }

        CurrentSwitchId = buildingId;

        const dp: PerformanceDatapoint = new PerformanceDatapoint('PlacesBuildingSwitchE2E', {
            timeout: PlacesMarkerTimeout,
        });
        dp.addCustomData({ buildingId, origin });

        PlacesBuildingSwitchE2E = createPlacesPerformanceMarker(dp);
    }

    return PlacesBuildingSwitchE2E;
}

export function PlacesBuildingSwitchE2EComplete(loadSuccessful: boolean, errorSource = '') {
    actActionIfExecuting(
        PlacesBuildingSwitchE2E,
        {
            loadSuccessful_1: loadSuccessful,
            errorSource: scrubForPii(errorSource),
        },
        loadSuccessful ? 'end' : 'endWithError'
    );

    CurrentSwitchId = '';
}

function PlacesBuildingSwitchE2ECancel(reason = '') {
    actActionIfExecuting(
        PlacesBuildingSwitchE2E,
        {
            reason,
        },
        'invalidate'
    );
}
