import { PlacesDashboardLoadE2EStart } from './placesDashboardLoadE2E';
import { PlacesHomepageV3LoadE2EStart } from './placesHomepageV3LoadE2E';
import { shouldStartDashboardPlt } from './utils/shouldStartDashboardPlt';

import type { PlacesBootSource } from './types/PlacesBootSource';
import { shouldStartPlacesAnalyticsAlt } from './utils/shouldStartPlacesAnalyticsAlt';
import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';

export default function placesFwkBootStartAction(bootSource: PlacesBootSource) {
    // Start Dashboard Page Load Time datapoint only when we are at dashboard page
    // There's a helper in owa called getWindowData(), if we need more information in the future, we can use that.
    if (shouldStartDashboardPlt(window?.location?.pathname || '')) {
        PlacesDashboardLoadE2EStart(bootSource);
        PlacesHomepageV3LoadE2EStart(bootSource);
    }

    // Start Analytics App Load Time datapoint only when we are at Analytics home page
    if (shouldStartPlacesAnalyticsAlt(window?.location?.pathname || '')) {
        placesAnalyticsAppLoadAction({
            scenario: 'PlacesFwkBootStart',
        });
    }
}
