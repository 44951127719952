import { logGreyError } from 'owa-analytics';
import { isRunningInMetaOSHub } from 'owa-config';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';
import { markFunction } from 'owa-performance';
import { placesSessionDataTable } from './sessionDataDatabase';

// Core logic extracted from packages/libraries/worker/owa-data-worker-client/src/actions/getOfflineSessionData.ts
// Tweaked to use metaos context and Places' indexDB
export const getOfflineSessionData = markFunction(getSessionData, 'offsd');

const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
// 9s is used because based on metrics, P95 session data takes up to 6s.
// This doesn't block boot, it delays "saveOfflineSessionData" call.
const NO_CACHE_DATA_FOUND_RETURN_TIMEOUT = 9000; /** 9 seconds */

async function getSessionData() {
    if (!isRunningInMetaOSHub()) {
        return;
    }
    const metaOSContext = await lazyGetContext.importAndExecute();
    if (!metaOSContext?.user?.id || !metaOSContext?.user?.tenant?.id) {
        return;
    }

    const ownerId = `${metaOSContext.user.id}@${metaOSContext.user.tenant.id}`;
    try {
        const placesSessionData = await placesSessionDataTable.get({ ownerId });

        if (!placesSessionData?.sessionData) {
            // Delay the resolve here to let network session data win
            return new Promise(resolve => {
                setTimeout(() => {
                    logGreyError('PlacesSessionDataNoCacheFound', undefined /** error */, {
                        ownerId,
                        timeout: NO_CACHE_DATA_FOUND_RETURN_TIMEOUT,
                    });
                    return resolve(undefined);
                }, NO_CACHE_DATA_FOUND_RETURN_TIMEOUT);
            });
        }

        const cachedTimestamp = placesSessionData?.timestamp ?? 0;
        const isSessionDataStale = cachedTimestamp < Date.now() - SEVEN_DAYS;
        if (placesSessionData?.sessionData && isSessionDataStale) {
            const error = new Error('Session data is stale. exceeded 7 days.');
            logGreyError('PlacesSessionDataStale', error /** error */, {
                ownerId,
                cachedTimestamp,
            });
            throw error;
        }

        return {
            isOffline: true,
            ...placesSessionData.sessionData,
        };
    } catch (e) {
        logGreyError('PlacesSessionDataTableReadError', e, { ownerId });
        throw e;
    }
}
