import { PlacesAnalyticsCoreEventBase } from './placesAnalyticsCoreEventBase';
import { PlacesMarkerTimeout } from './constants';
import { PerformanceCoreDatapoint } from 'owa-analytics';

/**
 * These strings are expensive to store in kusto. keeping it short.
 * This list is the source of truth.
 */
export const STEP_TO_WATERFALL_INDEX_MAP = {
    BootstrapCompleted: 1, // Completion of the./placesAnalyticsCoreEventBase
    PlacesSettingsFetched: 2, // Successful fetching of Places Settings
    RBACSettingsFetched: 3, // Successful fetching of RBAC Settings
    AnalyticsRootLoadBegin: 4, // HybridSpace Analytics component render initiated
    LatestReportDateFetched: 5, // Successful fetching of Latest Report Date
    BuildingIdSettingsFetched: 6, // Successful fetching of Building Ids from Places Settings
    BuildingsFetched: 7, // Successful fetching of Buildings
    TabLoadBegin: 8, // Tab page component render initiated (eg: HomePage, Room/Building/Deskpool page)
    TabLoadEnd: 9, // Tab page loaded
} as const;

/**
 * Measures from the beginning of the Places bootstrap (initializeState) in the owa-shared-bootstrap and ends on completion of home page load
 */
class PlacesAnalyticsAppLoadE2E extends PlacesAnalyticsCoreEventBase<
    typeof STEP_TO_WATERFALL_INDEX_MAP
> {
    constructor() {
        super(true);
    }

    protected createNewPerformanceCoreDatapoint(): PerformanceCoreDatapoint {
        return new PerformanceCoreDatapoint('PlacesAnalyticsAppLoadE2E', {
            timeout: PlacesMarkerTimeout,
        });
    }

    protected getStepToWaterfallIndexMap() {
        return STEP_TO_WATERFALL_INDEX_MAP;
    }
}

export const placesAnalyticsAppLoadE2E = new PlacesAnalyticsAppLoadE2E();
