import { type UTPlacesConfirmationOrigin } from 'owa-unified-telemetry';
import getEntryPoint from './getEntryPoint';
import { isHomepageRegex } from './isHomepageRegex';

// regex to only check if the person is either /places/ or /places or places/ or places
// any other combination will be false
const onHomePageOnlyRegex: RegExp = new RegExp(isHomepageRegex);

const getConfirmationOrigin = () => {
    const entryPoint = getEntryPoint();

    switch (entryPoint) {
        case 'emailConfirmationReminder':
            return 'EmailReminder';
        default:
            // no explicit entryPoint means dashboard. entryPoint is undefined
            if (onHomePageOnlyRegex.test(window.location.pathname)) {
                return 'Dashboard';
            }
            return 'Other'; // used when a string came from entryPoint
    }
};

export default getConfirmationOrigin;
