import {
    cancelPlacesAnalyticsPerfEventsAction,
    type PlacesAnalyticsPerfEventNames,
} from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import { placesAnalyticsAppLoadE2E } from '../placesAnalyticsAppLoadE2E';
import { placesAnalyticsAdminRoleSwitchE2E } from '../placesAnalyticsAdminRoleSwitchE2E';
import { placesAnalyticsTabLoadE2E } from '../placesAnalyticsTabLoadE2E';
import type { PlacesAnalyticsCoreEventBase } from '../placesAnalyticsCoreEventBase';

const eventObjMap: Record<
    PlacesAnalyticsPerfEventNames,
    InstanceType<typeof PlacesAnalyticsCoreEventBase>
> = {
    PlacesAnalyticsAppLoadE2E: placesAnalyticsAppLoadE2E,
    PlacesAnalyticsAdminRoleSwitchE2E: placesAnalyticsAdminRoleSwitchE2E,
    PlacesAnalyticsTabLoadE2E: placesAnalyticsTabLoadE2E,
};

/**
 * Orchestrates the cancellation of various scenarios for the places analytics events.
 */
orchestrator(cancelPlacesAnalyticsPerfEventsAction, ({ scenario, eventsToCancel }) => {
    if (eventsToCancel.includes('All')) {
        Object.values(eventObjMap).forEach(eventObj => eventObj?.cancel(scenario));
    } else {
        eventsToCancel.forEach(event => {
            const eventObj = eventObjMap[event as PlacesAnalyticsPerfEventNames];
            if (eventObj) {
                eventObj?.cancel(scenario);
            }
        });
    }
});
