import msalConfiguration from 'hybridspace-common/lib/constants/msalConfiguration';
import { PassiveMonitoringAriaTenantToken } from 'owa-analytics-start';
import { initializeWorker } from 'owa-data-worker-client/lib/actions/getOfflineSessionData';
import { start } from 'owa-start';
import { lazyBootstrap } from './lazyBootFunctions';
import { onFatalBootError } from './onFatalBootError';
import { overrideBootPromises } from './overrideBootPromises';

const app = 'Places';

start({
    app,
    loadBpos: true,
    startupAriaToken: PassiveMonitoringAriaTenantToken(), // OWA Passive Monitoring
    msalConfiguration,
    runBeforeStart: async () => {
        initializeWorker();
    },
    bootstrap: lazyBootstrap,
    onFatalBootError,
    overrideBootPromises: () => overrideBootPromises(app),
});
