import { addPASKeyMetric } from 'owa-performance';
import {
    PlacesDashboardLoadE2ECancel,
    PlacesDashboardLoadE2ECheckmark,
    PlacesDashboardLoadE2EComplete,
    PlacesDashboardLoadE2ECustomData,
} from './placesDashboardLoadE2E';
import {
    PlacesHomepageV3LoadE2ECancel,
    PlacesHomepageV3LoadE2ECheckmark,
    PlacesHomepageV3LoadE2EComplete,
    PlacesHomepageV3LoadE2ECustomData,
} from './placesHomepageV3LoadE2E';
import { isPlacesHomePage } from './utils/isPlacesHomePage';
import { isPlacesPeopleTab } from './utils/isPlacesPeopleTab';
import { isPlacesAnalyticsHomePage } from './utils/shouldStartPlacesAnalyticsAlt';
import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';

let firstLoad = true;

export default function placesFwkBootPtsCompleteAction(
    placesWebAppEnabled: boolean,
    placesPremiumEnabled: boolean,
    errorReason?: string,
    shouldUsePlacesLicensing?: boolean
) {
    if (firstLoad) {
        firstLoad = false;
        // Mark metric for PAS (aka.ms/pas)
        addPASKeyMetric('PlacesTenantSettingsLoaded');

        // Cancel markers that aren't relevant to current route
        const isHomePage = isPlacesHomePage(window?.location?.pathname || '');
        const isPeopleTab = isPlacesPeopleTab(window?.location?.pathname || '');
        const isAnalyticsHomePage = isPlacesAnalyticsHomePage(window?.location?.pathname || '');

        if (!isHomePage && !isPeopleTab && !isAnalyticsHomePage) {
            PlacesDashboardLoadE2ECancel('non-homepage');
            PlacesHomepageV3LoadE2ECancel('non-homepage');
        } else if (placesPremiumEnabled && isHomePage) {
            PlacesHomepageV3LoadE2ECheckmark('pts');
            PlacesDashboardLoadE2ECancel('cancel-people-marker-because-on-explore-page');
        } else {
            PlacesDashboardLoadE2ECheckmark('pts');
            PlacesHomepageV3LoadE2ECancel('cancel-explore-marker-because-on-people-page');
        }

        if (placesPremiumEnabled && isAnalyticsHomePage) {
            placesAnalyticsAppLoadAction({
                scenario: 'PlacesSettingsFetched',
            });
        }

        // add datapoint when we're using licensing
        if (shouldUsePlacesLicensing) {
            PlacesDashboardLoadE2ECustomData({ isUsingLicensing: true });
            PlacesHomepageV3LoadE2ECustomData({ isUsingLicensing: true });
        }

        // Failure case which are things that are not expected
        if (errorReason) {
            PlacesDashboardLoadE2EComplete(false /** success */, errorReason);
            PlacesHomepageV3LoadE2EComplete(false /** success */, errorReason);
            placesAnalyticsAppLoadAction({
                scenario: 'PlacesSettingsFetchFailed',
                errorSource: errorReason,
            });
            return;
        }

        // Success case. NoAccess is part of success case as we handled error case above. so, if we see NoAccess, it's expected from user's tenant settings.
        PlacesDashboardLoadE2ECustomData({
            exp: 'DsApiPlacesSettingsEnabled',
            val: placesWebAppEnabled,
            ...(placesWebAppEnabled ? undefined : { reason: 'NoAccess' }),
        });

        // if PlacesTenantSetting result is false, stop Perf Marker as it is a valid scenario.
        // we'll rely on checkmark to identify places tenant settings.
        if (!placesWebAppEnabled) {
            PlacesHomepageV3LoadE2ECustomData({ reason: 'NoAccess' });
            PlacesDashboardLoadE2EComplete(true /** success */);
            PlacesHomepageV3LoadE2EComplete(true /** success */);
            placesAnalyticsAppLoadAction({
                scenario: 'NonAccessPlacesSettingsFetched',
                errorSource: 'PlacesWebAppIsNotEnabled',
            });
        }
    }
}
