import type {
    IUTEPlacesBookReservationArgs,
    UTPlaceReservationOriginType,
} from 'owa-unified-telemetry';
import {
    type UTMeetingResponseType,
    type UTPlaceResourceType,
    type UTPlacesPhotoType,
} from 'owa-unified-telemetry';
import type { OwaDate } from 'owa-datetime';
import { asDate, differenceInCalendarDays, today } from 'owa-datetime';
import logWorkspacesUsage from './logWorkspacesUsage';
import type { Room } from 'owa-room-selection-types';

export interface BookingEvent {
    start: OwaDate;
    end: OwaDate;
    attendeeCount?: number;
    isOrganizer?: boolean;
    responseType?:
        | 'Accept'
        | 'Decline'
        | 'NoResponseReceived'
        | 'Organizer'
        | 'Tentative'
        | 'Unknown';
}

export default function logWorkspacesBooking(
    origin: UTPlaceReservationOriginType,
    event: BookingEvent,
    room: Room,
    placesType: 'DeskPool' | 'Room',
    photoType?: 'stock' | 'original',
    isRecurring?: boolean,
    usedBuildingFilter?: boolean,
    usedFloorFilter?: boolean,
    usedCapacityFilter?: boolean,
    usedFeaturesFilter?: boolean
) {
    const telemetry = <IUTEPlacesBookReservationArgs>{
        PlaceType: toUTPlaceResourceType(placesType),
        ActionSuccess: true,
        ReservationStartTime: asDate(event.start).toUTCString(),
        ReservationEndTime: asDate(event.end).toUTCString(),
        Origin: origin,
        Capacity: room.capacity ? room.capacity : -1,
        Rank: room.roomIndex == undefined ? -1 : room.roomIndex,
        CollaboratorCount: room.occupants?.length,
        IsRecurring: isRecurring,
        UsedBuildingFilter: usedBuildingFilter,
        UsedFloorFilter: usedFloorFilter,
        UsedCapacityFilter: usedCapacityFilter,
        UsedFeaturesFilter: usedFeaturesFilter,
        AttendeeCount: event.attendeeCount,
        IsEventOrganizer: event.isOrganizer,
    };

    if (photoType) {
        telemetry.PhotoType = toUTPlacesPhotoType(photoType);
    }

    if (event.responseType) {
        telemetry.EventResponse = toUTMeetingResponseType(event.responseType);
    }

    telemetry.AudioCapability = room.audioDeviceName ? true : false;
    telemetry.DisplayCapability = room.displayDeviceName ? true : false;
    telemetry.MTRCapability = room.mtrEnabled ? true : false;
    telemetry.VideoCapability = room.videoDeviceName ? true : false;
    telemetry.WheelchairCapability = room.isWheelChairAccessible
        ? room.isWheelChairAccessible
        : false;
    telemetry.DaysToEventStart = differenceInCalendarDays(event.start, today());

    logWorkspacesUsage(
        'PlacesWorkspacesBooking',
        {
            PlaceType: toUTPlaceResourceType(placesType),
            ReservationStartTime: asDate(event.start).toUTCString(),
            ReservationEndTime: asDate(event.end).toUTCString(),
        },
        {
            eventName: 'PlacesBookReservation',
            data: telemetry,
        }
    );
}

function toUTPlaceResourceType(placesType: 'DeskPool' | 'Room'): UTPlaceResourceType {
    switch (placesType) {
        case 'DeskPool':
            return 'Deskpool';
        case 'Room':
            return 'Room';
    }
}

function toUTPlacesPhotoType(photoType: 'stock' | 'original'): UTPlacesPhotoType {
    switch (photoType) {
        case 'stock':
            return 'Stock';
        case 'original':
            return 'Original';
    }
}

function toUTMeetingResponseType(
    responseType:
        | 'Accept'
        | 'Decline'
        | 'NoResponseReceived'
        | 'Organizer'
        | 'Tentative'
        | 'Unknown'
): UTMeetingResponseType {
    switch (responseType) {
        case 'Accept':
            return 'Accept';
        case 'Decline':
            return 'Decline';
        case 'NoResponseReceived':
            return 'NoResponseReceived';
        case 'Organizer':
            return 'Organizer';
        case 'Tentative':
            return 'Tentative';
        default:
            return 'Unknown';
    }
}
