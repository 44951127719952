import { PlacesAnalyticsCoreEventBase } from './placesAnalyticsCoreEventBase';
import { PlacesMarkerTimeout } from './constants';
import { PerformanceCoreDatapoint } from 'owa-analytics';

/**
 * These strings are expensive to store in kusto. keeping it short.
 * This list is the source of truth.
 */
export const STEP_TO_WATERFALL_INDEX_MAP = {
    HomePageActionableInsightsLoaded: 1, // Data loaded for Actionable Insights on HomePage
    HomePageSummaryBarLoaded: 2, // Data loaded for Summary Bar on HomePage
    HomePageSummaryCardLoaded: 3, // Data loaded for Summary Cards on HomePage
} as const;

/**
 * Measures the end-to-end loading process of the Places Analytics tab.
 */
class PlacesAnalyticsTabLoadE2E extends PlacesAnalyticsCoreEventBase<
    typeof STEP_TO_WATERFALL_INDEX_MAP
> {
    protected createNewPerformanceCoreDatapoint(): PerformanceCoreDatapoint {
        return new PerformanceCoreDatapoint('PlacesAnalyticsTabLoadE2E', {
            timeout: PlacesMarkerTimeout,
        });
    }

    protected getStepToWaterfallIndexMap() {
        return STEP_TO_WATERFALL_INDEX_MAP;
    }
}

export const placesAnalyticsTabLoadE2E = new PlacesAnalyticsTabLoadE2E();
