import type { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';
import { getBootType } from 'owa-config/lib/envDiagnostics';
import { timestamp } from 'owa-datetime';

import type { PlacesBootSource } from '../types/PlacesBootSource';

export function createPlacesPerformanceMarker(
    datapoint: PerformanceDatapoint | PerformanceCoreDatapoint,
    bootSource?: PlacesBootSource
) {
    // The SessionElapseTime added to action datapoints by framework is in sec (rather than msec), however in order
    // to measure the total load time for Places Maps load, we need the exact time in msec so that we can add it to E2ETimeElapsed.
    // Hence, adding SessionElapsedTime in msecs to this action datapoint
    const sessionElapsedTime = self.performance
        ? timestamp() - self.performance.timing.fetchStart
        : undefined;

    datapoint.addCustomData({
        SessionElapsedTime: sessionElapsedTime,
    });

    if (bootSource) {
        datapoint.addCustomData({ bootSource });
    }

    getBootType().then(owaBootType => datapoint.addCustomData({ owaBootType }));
    return datapoint;
}
