import type { CustomData, CustomWaterfallRange } from 'owa-analytics-types';

import { PerformanceCoreDatapoint } from 'owa-analytics';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { addCustomWaterfallIfExecuting } from './utils/addCustomWaterfallIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';
import { isExecuting } from './utils/isExecuting';

/**
 * These strings are expensive to store in kusto. keeping it short.
 * This list is the source of truth.
 */
export const STEP_TO_WATERFALL_INDEX_MAP: {
    [checkpoint: string]: CustomWaterfallRange;
} = {
    idbe: 1, // IndexedDB fetch ends
    gmfe: 2, // GetMapFeatures request ends (if network request is made)
    mcs: 3, // Map container starts to load
    mi: 4, // Map initialized
    bla: 5, // Map base layers added
    sla: 6, // Map symbol layers addded
    idle: 7, // Map becomes idle
};

/**
 * Measures E2E latency and latency breakdown for loading a map in Places Finder.
 * A performanceDatapoint is started when a new unit is selected in finder, and ends when the map finishes
 * loading all layers and markers.
 */
let PlacesMapsLoadE2E: PerformanceCoreDatapoint | undefined = undefined;
let prevUnitId: string | undefined = undefined;

export function PlacesMapsLoadE2EStart(unitId: string | undefined) {
    if (!unitId || unitId === prevUnitId) {
        return;
    }

    // If a new data point is initiated before the curent one ends
    // (user selects a new unit before the current map finishes loading),
    // cancel the previous datapoint and proceed with the new one.
    if (isExecuting(PlacesMapsLoadE2E)) {
        PlacesMapsLoadE2E.invalidate();
        PlacesMapsLoadE2E = undefined;
    }

    const dp = new PerformanceCoreDatapoint('PlacesMapsLoadE2E', {
        timeout: PlacesMarkerTimeout,
    });
    PlacesMapsLoadE2E = createPlacesPerformanceMarker(dp);
    prevUnitId = unitId;
}

export function PlacesMapsLoadE2EComplete(loadSuccessful: boolean, reason = '') {
    actActionIfExecuting(
        PlacesMapsLoadE2E,
        {
            loadSuccessful,
            reason,
        },
        loadSuccessful ? 'end' : 'endWithError' /* action */
    );
}

export function PlacesMapsLoadE2ECancel(reason = '') {
    actActionIfExecuting(
        PlacesMapsLoadE2E,
        {
            reason,
        },
        'invalidate' /* action */
    );
}

export const PlacesMapsLoadE2ECheckmark = (
    checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP
) => {
    addCustomWaterfallIfExecuting(
        PlacesMapsLoadE2E,
        STEP_TO_WATERFALL_INDEX_MAP[checkpoint],
        checkpoint as string
    );
};

export const PlacesMapsLoadE2ECustomData = (customData: CustomData) => {
    actActionIfExecuting(PlacesMapsLoadE2E, customData);
};
