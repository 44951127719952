import { logGreyError } from 'owa-analytics';
import { runAfterInitialRender } from 'owa-bundling-light/lib/utils/delayLoad';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';
import { isRunningInMetaOSHub } from 'owa-config';
import { lazySaveOfflineSessionData } from 'owa-data-worker-client';
import { getOfflineSessionData as getOwaOfflineSessionData } from 'owa-data-worker-client/lib/actions/getOfflineSessionData';
import { isBootFeatureEnabled } from 'owa-metatags';
import { networkRace } from 'owa-offline/lib/networkRace';
import { addBootCustomData } from 'owa-performance';
import getSessionData from 'owa-shared-start/lib/getSessionData';
import {
    getOfflineSessionData as getPlacesOfflineSessionData,
    saveOfflineSessionData,
} from 'places-user-session-stores/lib/sessionDatabase';

import type { SessionData } from 'owa-service/lib/types/SessionData';
/**
 * For V0, we're using OWA's offline session data infrastructure via owa-data-worker-client getOfflineSessionData, driven by msplaces-offline boot flight.
 * This has been working for initial bootstrapping of Places, however it has a couple drawbacks:
 *    - Worker thread is shared with all OWA apps, based on discussion with OWA Framework, it's not ideal to create forks of syncs in owa-offline-sync-manager/src/syncManager.ts.
 *    - We are calling Mail/Calendar related syncs which are unnecessary for Places and Places is not pre-authenticated with some of the downstream API.
 *
 * For V1, we're using Places' own offline session data infrastructure via places-user-session-stores saveOfflineSessionData, driven by msplaces-hosted-localsessiondata boot flight.
 * This is a lightweight version of OWA's offline sync, where we only store the necessary session data for Places to boot.
 *    - We'll store user config and user settings.
 *    - This is also only enabled in MetaOS for V1 as we are able to retrive user context (oid&tid) from the host
 */
export function overrideBootPromises(app: string): Promise<SessionData> {
    const hostedLocalSessionDataBootFlightEnabled = isBootFeatureEnabled(
        'msplaces-hosted-localsessiondata'
    );
    const isRunningOnMetaOS = isRunningInMetaOSHub();
    // Based on function documentation. for web, we can't read the right indexdb for a given user as we don't have the user context yet.
    // it is an opportunity to read from token in the future as we would need that for startupdata call. For now, it's scoped to metaos.
    const shouldUseNetworkOnly = !isRunningOnMetaOS;

    const shouldUsePlacesLocalSessionData =
        hostedLocalSessionDataBootFlightEnabled && isRunningOnMetaOS;

    return networkRace(
        shouldUsePlacesLocalSessionData ? 'PlacesSessionData' : 'SessionData',
        () => getSessionData(),
        () => {
            const sessionData = shouldUsePlacesLocalSessionData
                ? getPlacesOfflineSessionData()
                : getOwaOfflineSessionData(app);
            return sessionData as Promise<SessionData>;
        },
        (networkSD, offlineSD, _winner) => {
            runAfterInitialRender(() => {
                if (shouldUsePlacesLocalSessionData) {
                    return saveOfflineSessionData(networkSD).catch(_e => {
                        // no-op. logged in saveOfflineSessionData
                    });
                }
                return lazySaveOfflineSessionData
                    .importAndExecute(networkSD, offlineSD)
                    .catch(e => logGreyError('PlacesSaveOfflineSessionDataError', e));
            }, GovernPriority.Default);
        },
        0 /** networkTimeout */,
        shouldUseNetworkOnly /** shouldUseNetworkOnly */,
        false /** shouldUseOfflineOnly */
    ).then(({ value, customData }) => {
        addBootCustomData('SessionDataNetworkRace', customData);
        if (value === undefined) {
            // if we fail to get any value. trying getSessionData again as last chance to recover.
            const error = new Error('Failed to get session.');
            logGreyError('PlacesSessionDataNetworkRaceUndefined', error /** error */, customData);
            throw error;
        }
        return value;
    });
}
