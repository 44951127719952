import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import {
    placesAnalyticsAppLoadE2E,
    type STEP_TO_WATERFALL_INDEX_MAP,
} from '../placesAnalyticsAppLoadE2E';
import { placesAnalyticsAdminRoleSwitchE2E } from '../placesAnalyticsAdminRoleSwitchE2E';

/**
 * Orchestrates various scenarios for the places analytics app load and admin role switch events.
 * Handles checkpoints, errors, and completion markers based on the scenario.
 */
orchestrator(placesAnalyticsAppLoadAction, ({ scenario, customData, errorSource, errorObject }) => {
    let checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP | undefined;
    let reason: string | undefined;
    let shouldEndSuccessMarker = false;

    switch (scenario) {
        case 'PlacesFwkBootStart':
            placesAnalyticsAppLoadE2E.start();
            return;

        case 'AdminRoleSwitchStart':
            placesAnalyticsAdminRoleSwitchE2E.start();
            return;

        case 'BootstrapCompleted':
        case 'PlacesSettingsFetched':
        case 'RBACSettingsFetched':
        case 'AnalyticsRootLoadBegin':
        case 'LatestReportDateFetched':
        case 'BuildingIdSettingsFetched':
        case 'BuildingsFetched':
            checkpoint = scenario;
            break;

        case 'PlacesSettingsFetchFailed':
            shouldEndSuccessMarker = true;
            reason = 'completed-because-places-settings-fetch-failed';
            break;

        case 'NonAccessPlacesSettingsFetched':
            shouldEndSuccessMarker = true;
            reason = 'completed-because-places-webapp-is-not-enabled';
            break;

        case 'NonAdminRBACSettingsFetched':
            shouldEndSuccessMarker = true;
            reason = 'completed-becasue-non-admin-RBAC';
            break;

        case 'NoBuildingAvailable':
            shouldEndSuccessMarker = true;
            reason = 'completed-because-no-building-available';
            break;

        case 'NoBuildingSelected':
            shouldEndSuccessMarker = true;
            reason = 'completed-because-no-building-selected';
            break;

        case 'HomePageLoadBegin':
            checkpoint = 'TabLoadBegin';
            customData = {
                TabId: 'HomePage',
            };
            break;

        case 'HomePageLoadEnd':
            checkpoint = 'TabLoadEnd';
            shouldEndSuccessMarker = true;
            reason = 'completed-because-homepage-loaded';
            break;

        case 'FatalBootError':
            shouldEndSuccessMarker = true;
            reason = 'completed-because-of-fatal-boot-error';
            break;

        case 'EmployeeRoleSwitchStart':
            // This is to handle the case when user switches immediately to employee role after switching to admin role or directly after landing on space-analytics page.
            // In this case, we should cancel the perf markers for 'admin role switch' and 'app load' if they are already running.
            placesAnalyticsAppLoadE2E.cancel();
            placesAnalyticsAdminRoleSwitchE2E.cancel();
            return;
    }

    if (checkpoint) {
        // Logging to both events irrespective of the scenario.
        // checkpoints to adminRoleSwitchE2E event will be no-op if the event is not existing.
        placesAnalyticsAppLoadE2E.addCheckmark(checkpoint, customData);
        placesAnalyticsAdminRoleSwitchE2E.addCheckmark(checkpoint, customData);
    }

    if (errorSource || errorObject) {
        placesAnalyticsAppLoadE2E.complete({
            loadSuccessful: false,
            errorSource: errorSource ?? scenario,
            errorObject,
        });
        placesAnalyticsAdminRoleSwitchE2E.complete({
            loadSuccessful: false,
            errorSource: errorSource ?? scenario,
            errorObject,
        });
        return;
    }

    if (shouldEndSuccessMarker) {
        placesAnalyticsAppLoadE2E.complete({
            loadSuccessful: true,
            reason,
        });
        placesAnalyticsAdminRoleSwitchE2E.complete({
            loadSuccessful: true,
            reason,
        });
    }
});
