import { placesLoaderAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import { PlacesDashboardLoadE2ECheckmark } from '../placesDashboardLoadE2E';

type SourceTypes = Parameters<typeof PlacesDashboardLoadE2ECheckmark>[0];

orchestrator(placesLoaderAction, ({ loaderName }) => {
    let source: SourceTypes;
    switch (loaderName) {
        case 'PlacesWorkplanLoader':
            source = 'wpls'; /** workplan loader started */
            break;
    }

    if (source) {
        PlacesDashboardLoadE2ECheckmark(source);
    }
});
