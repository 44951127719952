import { placesGuidanceCardsLoadAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import {
    PlacesGuidanceCardsLoadStart,
    PlacesGuidanceCardsLoadComplete,
    PlacesGuidanceCardsLoadCustomData,
    PlacesGuidanceCardsLoadCancel,
} from '../placesGuidanceCardsLoad';

// the maximum number of guidance cards to load for 1 week
let guidanceCardCounter = 0;
let shouldEndSuccessMarker = false;
orchestrator(
    placesGuidanceCardsLoadAction,
    ({ rootComponentName, guidanceCardCount, errorObject }) => {
        switch (rootComponentName) {
            case 'Start':
                PlacesGuidanceCardsLoadStart();
                break;

            case 'AddGuidanceCard':
                guidanceCardCounter++;
                break;

            case 'End':
                if (guidanceCardCount != undefined) {
                    if (guidanceCardCount == guidanceCardCounter) {
                        shouldEndSuccessMarker = true;
                    } else {
                        PlacesGuidanceCardsLoadCancel('Guidance cards count mismatch');
                    }
                } else {
                    PlacesGuidanceCardsLoadCancel('Total guidance count not provided');
                }
                break;
        }

        if (errorObject) {
            PlacesGuidanceCardsLoadComplete(false, errorObject);
            return;
        }

        if (shouldEndSuccessMarker) {
            PlacesGuidanceCardsLoadCustomData({ guidanceCardCounter });
            PlacesGuidanceCardsLoadComplete(true /** load successful */);
            guidanceCardCounter = 0;
            shouldEndSuccessMarker = false;
        }
    }
);
