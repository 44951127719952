import { getQueryStringParameter, nodeParseQueryString } from 'owa-querystring';
import { isNullOrWhiteSpace } from 'owa-string-utils';

export default function getUtmSource(qspOverride?: string): string | null {
    // Check QSP override first. this is split out in case parseQueryString(qspOverride)?.['utm_source] returns empty, then we'll fallback to window QSP.
    const utmSourceOverride = qspOverride
        ? nodeParseQueryString(qspOverride)?.['utm_source']
        : undefined;
    const utmSource = utmSourceOverride ? utmSourceOverride : getQueryStringParameter('utm_source');
    if (isNullOrWhiteSpace(utmSource)) {
        return '';
    }
    return utmSource;
}
