import { PerformanceCoreDatapoint } from 'owa-analytics';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';

import type { IPlacesHybridGuidanceLoadE2EArgs } from 'owa-unified-telemetry';
import type { CustomData } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace';

/**
 * Measures from the beginning of the Hybrid Guidance bootstrap (initializeState) and ends when all elements are loaded.
 */
let PlacesHybridGuidanceLoadE2E: PerformanceCoreDatapoint | undefined = undefined;

export function PlacesHybridGuidanceLoadE2EStart() {
    const dp = new PerformanceCoreDatapoint('PlacesHybridGuidanceLoadE2E', {
        timeout: PlacesMarkerTimeout,
    });
    PlacesHybridGuidanceLoadE2E = createPlacesPerformanceMarker(dp);
    return PlacesHybridGuidanceLoadE2E;
}

export function PlacesHybridGuidanceLoadE2EComplete(
    loadSuccessful: boolean,
    errorObject?: TraceErrorObject
) {
    // TODO: Data is casted because the current interface doesn't support optional data.
    PlacesHybridGuidanceLoadE2E?.addUnifiedTelemetryData({
        eventName: 'HybridGuidancePageLoadE2E',
        data: {} as IPlacesHybridGuidanceLoadE2EArgs,
    });
    actActionIfExecuting(
        PlacesHybridGuidanceLoadE2E,
        {
            loadSuccessful_1: loadSuccessful,
        },
        loadSuccessful ? 'end' : 'endWithError',
        errorObject
    );
}

let shouldRunLoadE2ECancel = true;
export function PlacesHybridGuidanceLoadE2ECancel(reason = '') {
    if (shouldRunLoadE2ECancel) {
        shouldRunLoadE2ECancel = false;
        actActionIfExecuting(PlacesHybridGuidanceLoadE2E, { reason }, 'invalidate');
    }
}
export const PlacesHybridGuidanceLoadE2ECustomData = (customData: CustomData) => {
    actActionIfExecuting(PlacesHybridGuidanceLoadE2E, customData);
};
