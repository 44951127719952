import { PerformanceCoreDatapoint } from 'owa-analytics';
import { addPASKeyMetric } from 'owa-performance';
import { PlacesMarkerTimeout } from './constants';
import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { addBootCustomData } from './utils/addBootCustomData';
import { addCustomWaterfallIfExecuting } from './utils/addCustomWaterfallIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';

import type { PlacesBootSource } from './types/PlacesBootSource';
import type { IUTEPlacesBootExplorePageE2EArgs } from 'owa-unified-telemetry';
import type { CustomData } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace';
/**
 * These strings are expensive to store in kusto. keeping it short.
 * This list is the source of truth.
 */
export const STEP_TO_WATERFALL_INDEX_MAP = {
    ps: 1, // post start in bootstrap
    hd: 2, // home dashboard mounted. work location info started. this is useful as we can check the time difference between main module until home dashboard lazy loaded.
    hdc: 3, // home dashboard content mounted. work location info ended
    cc: 4, // collaborator card container mounted
    svc: 5, // collaborators row content mounted (has data for UI to render)
    pim: 6, // people in meetings row content mounted (has data for UI to render)
    pts: 7, // places tenant setting result received
} as const;

/**
 * Measures from the beginning of the Places bootstrap (initializeState) in the owa-shared-bootstrap and ends
 */
let PlacesHomepageV3LoadE2E: PerformanceCoreDatapoint | undefined = undefined;

let shouldRunLoadE2EStart = true;
export function PlacesHomepageV3LoadE2EStart(bootSource: PlacesBootSource) {
    if (shouldRunLoadE2EStart) {
        shouldRunLoadE2EStart = false;
        const dp = new PerformanceCoreDatapoint('PlacesHomepageV3LoadE2E', {
            timeout: PlacesMarkerTimeout,
        });
        PlacesHomepageV3LoadE2E = createPlacesPerformanceMarker(dp, bootSource);
    }
    return PlacesHomepageV3LoadE2E;
}

let shouldRunLoadE2EComplete = true;
export function PlacesHomepageV3LoadE2EComplete(
    loadSuccessful: boolean,
    errorSource = '',
    errorObject?: TraceErrorObject
) {
    if (shouldRunLoadE2EComplete && PlacesHomepageV3LoadE2E) {
        shouldRunLoadE2EComplete = false;
        // TODO: Data is casted because the current interface doesn't support optional data. loicbe is looking into this.
        PlacesHomepageV3LoadE2E.addUnifiedTelemetryData({
            eventName: 'PlacesBootExplorePageE2E',
            data: {} as IUTEPlacesBootExplorePageE2EArgs /** PerfProperties and PerfCustomWaterfall is handled in logUnifiedDatapoint & mergeEvents */,
        });

        addBootCustomData(PlacesHomepageV3LoadE2E);
        addPASKeyMetric('PlacesAppLoadSucceeded');

        const errorSourceObj = loadSuccessful
            ? {}
            : { errorSource: !!errorSource ? errorSource : 'EmptyErrorSourcePleaseUpdate' };
        actActionIfExecuting(
            PlacesHomepageV3LoadE2E,
            {
                loadSuccessful_1: loadSuccessful,
                ...errorSourceObj,
            },
            loadSuccessful ? 'end' : 'endWithError',
            errorObject
        );
    }
}

let shouldRunLoadE2ECancel = true;
export function PlacesHomepageV3LoadE2ECancel(reason = '') {
    if (shouldRunLoadE2ECancel) {
        shouldRunLoadE2ECancel = false;
        actActionIfExecuting(PlacesHomepageV3LoadE2E, { reason }, 'invalidate');
    }
}

export const PlacesHomepageV3LoadE2ECheckmark = (
    checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP
) => {
    addCustomWaterfallIfExecuting(
        PlacesHomepageV3LoadE2E,
        STEP_TO_WATERFALL_INDEX_MAP[checkpoint],
        checkpoint as string
    );
};

export const PlacesHomepageV3LoadE2ECustomData = (customData: CustomData) => {
    actActionIfExecuting(PlacesHomepageV3LoadE2E, customData);
};
