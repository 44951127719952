import {
    getQueryStringParameter,
    hasQueryStringParameter,
    nodeParseQueryString,
} from 'owa-querystring';
import { isNullOrWhiteSpace } from 'owa-string-utils';

export default function getEntryPoint(qspOverride?: string): string | null {
    // Check QSP override first. this is split out in case nodeParseQueryString(qspOverride)?.ep returns empty, then we'll fallback to window QSP.
    const qspEpIndex = qspOverride?.indexOf('ep') || -1;
    const qspEpParamString =
        qspOverride && qspEpIndex > -1 ? qspOverride.slice(qspEpIndex) : qspOverride;

    const qspoverrideEntryPoint = qspEpParamString
        ? nodeParseQueryString(qspEpParamString)?.ep
        : undefined;
    let entryPoint = qspoverrideEntryPoint ? qspoverrideEntryPoint : getQueryStringParameter('ep');
    if (
        (entryPoint == undefined || isNullOrWhiteSpace(entryPoint)) &&
        hasQueryStringParameter('guidanceId')
    ) {
        // check for guidance id in the query string, this is a stop-gap measure as guidance deeplinks has no 'ep' qparam
        entryPoint = 'guidanceInvite';
    }
    return entryPoint;
}
