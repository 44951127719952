import { placesFwkRootComponentMountedAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import {
    PlacesHomepageV3LoadE2ECheckmark,
    PlacesHomepageV3LoadE2EComplete,
} from '../placesHomepageV3LoadE2E';

type SourceTypes = Parameters<typeof PlacesHomepageV3LoadE2ECheckmark>[0];

orchestrator(placesFwkRootComponentMountedAction, ({ rootComponentName, errorObject }) => {
    let source: SourceTypes | undefined = undefined;
    let shouldEndSuccessMarker = false;
    switch (rootComponentName) {
        case 'PlacesExplorePageEmptyContent':
            shouldEndSuccessMarker =
                true; /** This happens when user has no building set OR there are no buildings in tenants */
            break;
        case 'PlacesExplorePageRoot':
            source = 'hd'; /** home dashboard mounted */
            break;
        case 'PlacesExplorePageRootContent':
            source = 'hdc'; /** home dashboard content mounted */
            break;

        case 'PlacesExplorePageCollaborators':
            source = 'svc'; /** collaborators row mounted */
            shouldEndSuccessMarker = true; // end when we have collaborators data. this will be a race with people in meeting.
            break;

        case 'PlacesExplorePagePeopleInMeetings':
            source = 'pim'; /** people in your meetings row mounted */
            shouldEndSuccessMarker = true; // end when we have people in meetings data. this will be a race with collaborators.
            break;

        case 'PlacesExplorePageCollaboratorsCard':
            source = 'cc'; /** collaborator card content mounted */
            break;
    }

    if (source != undefined) {
        if (errorObject) {
            PlacesHomepageV3LoadE2EComplete(false /** load failed */, source, errorObject);
        } else {
            PlacesHomepageV3LoadE2ECheckmark(source);
        }
    }

    if (shouldEndSuccessMarker) {
        PlacesHomepageV3LoadE2EComplete(true /** load successful */);
    }
});
