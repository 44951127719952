import type { PerformanceDatapoint } from 'owa-analytics';
import { isExecuting } from './isExecuting';

import type { CustomWaterfallRange, CustomData } from 'owa-analytics-types';

export const addCustomWaterfallIfExecuting = (
    datapoint: PerformanceDatapoint | undefined,
    waterfallRange: CustomWaterfallRange,
    checkpoint: string,
    customData?: CustomData
) => {
    if (
        isExecuting(datapoint) &&
        // prevent warning in console
        !datapoint.isWaterfallCheckpointDefined(waterfallRange)
    ) {
        datapoint.addToCustomWaterfall(waterfallRange, checkpoint, true /** discard if defined */);

        if (customData) {
            datapoint.addCustomData(customData);
        }
    }
};
