import { logUsage } from 'owa-analytics';
import { scrubForPii } from 'owa-config';
import React from 'react';

export interface ErrorBoundaryLoggedData {
    ActionSuccess: boolean;
    ActionFailureInfo?: React.ErrorInfo;
}

/* eslint-disable-next-line owa-custom-rules/prefer-react-functional-components -- (https://aka.ms/OWALintWiki)
 * This react class component should be re-factored as functional component, if is not possible, write a justification.
 *	> When implementing a simple component, write it as a functional component rather than as a class component */
export class ErrorBoundaryLogged extends React.Component<
    React.PropsWithChildren<{
        appName?: string;
        parentName: string;
        runLogCallback: (data: ErrorBoundaryLoggedData) => void;
        resetAndThrowAfterCatch?: boolean;
    }>
> {
    state: {
        error: Error | null;
        runLogCallback: (data: ErrorBoundaryLoggedData) => void;
    };
    constructor(props: {
        appName?: string;
        parentName: string;
        runLogCallback: (data: ErrorBoundaryLoggedData) => void;
        resetAndThrowAfterCatch?: boolean;
    }) {
        super(props);
        this.state = {
            error: null,
            runLogCallback: props.runLogCallback,
        };
    }

    static getDerivedStateFromError(error: Error) {
        // Use to re render while the error is catched
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const appName = this.props.appName ?? 'Places';
        // log failure
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logUsage(`${appName}ErrorBoundaryLogged`, {
            source: this.props.parentName,
            error: scrubForPii(error.message),
        });
        this.state.runLogCallback({ ActionSuccess: false, ActionFailureInfo: errorInfo });
        if (this.props.resetAndThrowAfterCatch) {
            this.setState({ error: null });
            throw error;
        }
    }

    componentDidMount() {
        // log success
        this.state.runLogCallback({ ActionSuccess: true });
    }

    render() {
        // We just render whatever we receive, and let OWA handle the exception
        return this.props.children;
    }
}
