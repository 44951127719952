import { isRunningInMetaOSHub, scrubForPii } from 'owa-config';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';
import { getHostLocation } from 'owa-url/lib/hostLocation';

import { type UTPlacesAppLoadOrigin } from 'owa-unified-telemetry';

import logPlacesEvent from './logPlacesEvent';
import getOrigin from './utils/getOrigin';
import getUtmSource from './utils/getUtmSource';

import type { UTPageType } from 'owa-unified-telemetry';

let shouldLogAppBoot = true;
export default async function logAppBoot(
    LoadedPage: UTPageType | undefined,
    ActionSuccess: boolean,
    ActionFailureInfo?: React.ErrorInfo | string
) {
    if (shouldLogAppBoot) {
        shouldLogAppBoot = false;

        let metaOsRelativeDeepLink = undefined;
        if (isRunningInMetaOSHub()) {
            // subPageId is used for relative link to support deeplinking.
            // so, we can reuse this to extrct the QSP of the relative link.
            // Example format: https://teams.microsoft.com/l/entity/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc?context=%7b%22subEntityId%22%3A%22%2F%3Fep%3DsamuleEmailSig%26utm_source%3DsamuleEmailSig%22%7dhttps://teams.microsoft.com/l/entity/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc?context=%7b%22subEntityId%22%3A%22%2F%3Fep%3DsamuleTest%26utm_source%3DsamuleTest%22%7d
            const context = await lazyGetContext.importAndExecute();
            metaOsRelativeDeepLink = context.page.subPageId;
        }

        const clientOrigin = getOrigin(metaOsRelativeDeepLink);
        const Origin =
            clientOrigin === 'WelcomeEmail'
                ? 'WelcomeEmail'
                : clientOrigin === 'ConfirmationEmail'
                ? 'ConfirmationEmail'
                : clientOrigin === 'InviteEmail'
                ? 'InviteEmail'
                : clientOrigin === 'TeamsMessageExtension'
                ? 'TeamsMessageExtension'
                : clientOrigin === 'CalendarPeekOthersInOffice'
                ? 'CalendarPeekOthersInOffice'
                : clientOrigin === 'OwaWhlCalendarSurface'
                ? 'OwaWhlCalendarSurface'
                : clientOrigin === 'CalendarTodaysOthersInOffice'
                ? 'CalendarTodaysOthersInOffice'
                : clientOrigin === 'GuidanceInvite'
                ? 'GuidanceInvite'
                : 'Other';

        const utmSource = getUtmSource(metaOsRelativeDeepLink);

        logPlacesEvent(
            {
                eventName: 'PlacesAppLoad',
                data: {
                    ActionSuccess,
                    Origin,
                    ...(ActionFailureInfo
                        ? { ActionFailureInfo: scrubForPii(JSON.stringify(ActionFailureInfo)) }
                        : {}),
                    PathName: getHostLocation().pathname,
                    UtmSource: utmSource ?? '',
                    LoadedPage,
                },
            },
            { path: getHostLocation().pathname },
            metaOsRelativeDeepLink
        );
    }
}
