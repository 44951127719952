import React from 'react';
import { PlacesDashboardLoadE2ECheckmark } from './placesDashboardLoadE2E';

import type { STEP_TO_WATERFALL_INDEX_MAP } from './placesDashboardLoadE2E';
/**
 * Helper hook to mark a checkpoint for PlacesDashboardLoadE2E
 * Check hybridspace-performance-datapoints package for more details.
 */
export function usePlacesDashboardLoadE2ECheckpoint(
    successCondition: boolean,
    checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP
) {
    const hasMarked = React.useRef(false);
    React.useEffect(() => {
        if (!hasMarked.current && successCondition) {
            PlacesDashboardLoadE2ECheckmark(checkpoint);
            hasMarked.current = true;
        }
    }, [successCondition]);
}
