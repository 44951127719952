import logPlacesEvent from './logPlacesEvent';

// TOOD: Fix any
let shouldLogPlacesAdminPageLoad = true;
export function logPlacesAdminPageLoad(data: any) {
    if (shouldLogPlacesAdminPageLoad) {
        shouldLogPlacesAdminPageLoad = false;
        logPlacesEvent({ eventName: 'PlacesViewAdminPortal', data });
    }
}
