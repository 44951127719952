export type PlacesPageLoaderType =
    | 'PlacesWorkplanLoader'
    | 'PlacesHomeRouteLayoutLoader'
    | 'PlacesBuildingLoader'
    | 'HybridGuidanceWorkGroupLoader';

export function isPlacePageLoaderType(loaderType: string): loaderType is PlacesPageLoaderType {
    return (
        loaderType === 'PlacesWorkplanLoader' ||
        loaderType === 'PlacesHomeRouteLayoutLoader' ||
        loaderType === 'PlacesBuildingLoader' ||
        loaderType === 'HybridGuidanceWorkGroupLoader'
    );
}
