import { placesFwkRootComponentMountedAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import {
    PlacesAgendaPanelLoadE2ECheckmark,
    PlacesAgendaPanelLoadE2EComplete,
    PlacesAgendaPanelLoadE2EStart,
    PlacesAgendaPanelLoadE2ECancel,
} from '../placesAgendaPanelLoadE2E';

type SourceTypes = Parameters<typeof PlacesAgendaPanelLoadE2ECheckmark>[0];

orchestrator(placesFwkRootComponentMountedAction, ({ rootComponentName, errorObject }) => {
    let source: SourceTypes | undefined = undefined;
    let shouldEndSuccessMarker = false;
    switch (rootComponentName) {
        case 'PlacesAgendaPanelRoot' /** start marker when */:
            PlacesAgendaPanelLoadE2EStart();
            break;
        case 'PlacesAgendaPanelRootHide':
            PlacesAgendaPanelLoadE2ECancel(
                'Agenda Panel is hidden'
            ); /** to cancel the marker when agenda panel doesn't show in the homepage on small screen */
            break;
        case 'PlacesAgendaDayScheduleViewContent' /** component which has the details of calendar events */:
            source = 'calendarView';
            shouldEndSuccessMarker = true;
            break;
        case 'PlacesAgendaDayScheduleSurfaceEmpty' /** empty state of the day schedule */:
            shouldEndSuccessMarker = true;
            break;
    }

    if (source != undefined) {
        if (errorObject) {
            PlacesAgendaPanelLoadE2EComplete(false /** load failed */, source, errorObject);
        } else {
            PlacesAgendaPanelLoadE2ECheckmark(source);
        }
    }

    if (shouldEndSuccessMarker) {
        PlacesAgendaPanelLoadE2EComplete(true /** load successful */);
    }
});
